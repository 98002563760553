import { createStore } from 'vuex';
import API from "../service/API";
import router from '../router.js';

export default createStore({
  state: {
    staticMenuInactive: true,
    overlayMenuActive: false,
    mobileMenuActive: false,
    mantenimiento: false,
    loading: false,
    conexion: false,
    token: null,
    autenticado: false,
    display: {
      login: true,
      recovery: false,
    },
    fechaini: null,
    fecha: null,
    fechaletras: null,
    tope: null,
    year: null,
    mes: null,
    id: null,
    foto: 'foto',
    url: null,
    correo: null,
    usuario: null,
    nombre: null,
    apellido: null,
    nucleo: null,
    rol: 0,
    periodo: null,
    verificacion: false,
    estatus: false,
    mobile: false,
    error: null,
    admin: false,
    paginas: [],
    menu : [],
    productos: [],
    menuser: [],
    menuadmin: [],
    menu_ini: [],
    seleccion: {
      caso: null,
      id: 0
    },
    resumen: {},
    matricula: {},
    petro: 0,
    dolar: 0,
    preinscripcion: null,
    conectados: 0,
    notificacion: false,
    notificaciones: [],
    deuda: false,
    imp: false,
    impresora: {
      sup: 0,
      izq: 0,
      font: 'Helvetia',
      size: 10,
    },
    titulo: null
  },
  mutations: {
    Loading(){
      this.state.loading = false;	
    },
    Estatus(){
      this.state.url = process.env.VUE_APP_API+'doc/';	
      const Consulta = new API();
      Consulta.Estatus().then(response => {
        //this.state.error = response; 
        this.state.estatus = response.estatus;
        this.state.conectados = response.conectados+1;
        this.state.year = response.year;
        this.state.mes = response.mes;
        this.state.fechaini = response.fecha;
        this.state.fechaletras = response.fechaletras;
        this.state.fecha = response.fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
        localStorage.setItem('Fecha', this.state.fecha);
      });
    },
    Mobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        && screen.width<1024) {
        this.state.mobile = true;
      } else {
        this.state.mobile = false;
      }    
    },
    Login(state,valores){
      //this.state.error = valores;
      this.state.autenticado = true;	
      this.state.id = valores.id;
      localStorage.setItem('Token', valores.token);
      this.state.token = valores.token
      this.state.usuario = valores.nombre+' '+valores.apellido;   
      this.state.nombre = valores.nombre;        
      if(valores.foto){this.state.foto = valores.foto;}
      this.state.apellido = valores.apellido;   
      this.state.nucleo = valores.nucleo;
      this.state.correo = valores.correo;
      this.state.menu_ini = this.state.menu;
      this.state.admin = valores.admin;
      this.state.staticMenuInactive = false;
      this.state.mobileMenuActive = true;
      this.state.menu = valores.menu;
      if(valores.notificaciones.length>0){
        this.state.notificaciones = valores.notificaciones;
        this.state.notificacion = true;
      }

      if (valores.admin) {
        this.state.menuadmin = valores.menuadmin;
        this.state.menuadmin.forEach(element => {
            this.state.paginas.push(element);
        });  
      }
      this.state.menu.forEach(element => {
        element.items.forEach(element => {
          this.state.paginas.push(element);
        }); 
      }); 

    },
    Validar(state,pagina){
      this.state.titulo = null;
      this.state.insert = false;
      this.state.edit = false;
      this.state.delete = false;
      let pag = this.state.paginas.filter(val => val.to == pagina.path);
      if(pag.length){
        this.state.titulo = pag[0].label;
      } else {
        router.push({ path: '/' });
      }
    },
    Seleccion(state,valores){
      this.state.seleccion.caso = valores.caso;
      this.state.seleccion.id = valores.id;
    },
    Matricula(state,valores){
      this.state.matricula = valores.matricula;
    },
    Logout(){
      const Consult = new API('Autenticacion','Exit');
      Consult.Ini();
      this.state.token = null;
      localStorage.removeItem('Token');
      localStorage.removeItem('Fecha');
      this.state.autenticado = false;
      this.state.staticMenuInactive = true;
      this.state.mobileMenuActive = false;
      this.state.usuario = null;   
      this.state.correo = null;
      this.state.admin = false;
      this.state.paginas = [];
      this.state.menu = this.state.menu_ini;
      this.state.menuadmin = [];
      router.push({ path: '/' });
      this.state.display.login = true;
    },
    MenuToggle(){
      if(this.state.autenticado){
        if (this.state.mobile) {
          this.state.mobileMenuActive = !this.state.mobileMenuActive
        }
        else {
          this.state.staticMenuInactive = !this.state.staticMenuInactive;
        }   
      } else {
          this.state.staticMenuInactive = true;
          this.state.overlayMenuActive = false;
          this.state.mobileMenuActive = false;
      }
    }

  },
  actions: {
  },
  modules: {
  }
})
